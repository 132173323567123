import { BaseService } from "@core-ui/api-client";
export class UserService extends BaseService {
    constructor(api) {
        super(api);
    }
    create(createData) {
        return this.api.post("/users", {}, createData);
    }
    update(id, updateData) {
        return this.api.put("/users/{userId}", { userId: id }, updateData);
    }
    patchUpdate(userId, updateData) {
        return this.api.patch("/users/{userId}", { userId }, updateData);
    }
    getOne(id) {
        return this.api.get("/users/{userId}", { userId: id });
    }
    getMany(filter) {
        return this.api.get("/users", filter);
    }
    delete(id) {
        return this.api.delete("/users/{userId}", { userId: id });
    }
    login(data) {
        return this.api.post("/auth/login", {}, data);
    }
}

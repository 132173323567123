import { BaseSDK } from "@core-sdk/core";
import { CategoryService, RoleService, UserService, JobService } from "./services";
export class JobsListingSDK extends BaseSDK {
    static instance = null;
    userService;
    categoryService;
    roleService;
    jobService;
    constructor(config) {
        super(config);
        this.userService = new UserService(this.api);
        this.categoryService = new CategoryService(this.api);
        this.roleService = new RoleService(this.api);
        this.jobService = new JobService(this.api);
    }
    static getInstance = (config) => {
        if (!this.instance) {
            this.instance = new JobsListingSDK(config || { apiEndpoint: "no-api-end-point" });
        }
        return this.instance;
    };
    getUserControl() {
        return {
            ...this.getBaseControl(this.userService),
        };
    }
    getCategoryControl() {
        return {
            ...this.getBaseControl(this.categoryService),
        };
    }
    getRoleControl() {
        return {
            ...this.getBaseControl(this.roleService),
        };
    }
    getJobControl() {
        return {
            ...this.getBaseControl(this.jobService),
        };
    }
    exportAnydayJob(jobId, categoryId, htmlString) {
        return this.jobService.exportAnydayJob(jobId, categoryId, htmlString);
    }
    login(data) {
        return this.userService.login(data);
    }
}
export * from "./types";
export default JobsListingSDK;

import { BaseService } from "@core-ui/api-client";
export class RoleService extends BaseService {
    constructor(api) {
        super(api);
    }
    create(createData) {
        return this.api.post("/roles", {}, createData);
    }
    update(id, updateData) {
        return this.api.put("/roles/{roleId}", { roleId: id }, updateData);
    }
    patchUpdate(roleId, updateData) {
        return this.api.patch("/roles/{roleId}", { roleId }, updateData);
    }
    getOne(id) {
        return this.api.get("/roles/{roleId}", { roleId: id });
    }
    getMany(filter) {
        return this.api.get("/roles", filter);
    }
    delete(id) {
        return this.api.delete("/roles/{roleId}", { roleId: id });
    }
}

import { BaseService } from "@core-ui/api-client";
export class JobService extends BaseService {
    constructor(api) {
        super(api);
    }
    create(createData) {
        return this.api.post("/jobs", {}, createData);
    }
    update(id, updateData) {
        return this.api.put("/jobs/{jobId}", { jobId: id }, updateData);
    }
    patchUpdate(jobId, updateData) {
        return this.api.patch("/jobs/{jobId}", { jobId }, updateData);
    }
    getOne(id) {
        return this.api.get("/jobs/{jobId}", { jobId: id });
    }
    getMany(filter) {
        return this.api.get("/jobs", filter);
    }
    delete(id) {
        return this.api.delete("/jobs/{jobId}", { jobId: id });
    }
    exportAnydayJob(jobId, categoryId, htmlString) {
        return this.api.post("/crawler/aniday", {}, { htmlString, categoryId, jobId });
    }
}

import { BaseStore, makeObservable, observable } from "@core-ui/react-mobx-state";
export class CategoryStore extends BaseStore {
    error = null;
    categories;
    category;
    filterData;
    activeCateId;
    constructor() {
        super();
        makeObservable(this, {
            error: observable,
            categories: observable,
            category: observable,
            filterData: observable,
            activeCateId: observable,
        });
    }
}

import { jsx as _jsx } from "react/jsx-runtime";
import { createStore } from "@core-ui/react-mobx-state";
import { UserStore } from "./UserStore";
import { RoleStore } from "./RoleStore";
import { CategoryStore } from "./CategoryStore";
import { NotifyStore } from "./NotifyStore";
import JobsListingSDK from "@core-sdk/jobs-listing";
import { useEffect } from "react";
import { JobStore } from "./JobStore";
export class JobsListingStore {
    userStore;
    roleStore;
    categoryStore;
    notiStore;
    jobStore;
    constructor() {
        this.userStore = new UserStore();
        this.roleStore = new RoleStore();
        this.categoryStore = new CategoryStore();
        this.notiStore = new NotifyStore();
        this.jobStore = new JobStore();
    }
}
export const jobsListingStore = createStore(new JobsListingStore());
export const useJobsListingStore = jobsListingStore.useStore;
const Provider = jobsListingStore.Provider;
export const JobsListingProvider = ({ children, config }) => {
    useEffect(() => {
        JobsListingSDK.getInstance(config.apiConfig);
    }, []);
    return _jsx(Provider, { children: children });
};
export * from "./UserStore";
export * from "./RoleStore";
export * from "./CategoryStore";
export * from "./NotifyStore";
export * from "./JobStore";

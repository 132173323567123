import { createApi } from "@core-ui/api-client";
export class BaseSDK {
    api;
    constructor(config) {
        this.api = createApi(config);
    }
    handleErrorResult(error) {
        const message = error ? typeof error === "string" ? error : error?.message || "" : "";
        throw new Error(message);
    }
    handleApiResult = (dataReturn) => {
        if (dataReturn.data) {
            return dataReturn.data;
        }
        else {
            return dataReturn;
        }
    };
    getBaseControl = (service) => {
        return {
            create: async (createData) => {
                try {
                    const rs = await service.create(createData);
                    return this.handleApiResult(rs);
                }
                catch (error) {
                    this.handleErrorResult(error);
                }
            },
            update: async (id, updateData) => {
                try {
                    const rs = await service.update(id, updateData);
                    return this.handleApiResult(rs);
                }
                catch (error) {
                    this.handleErrorResult(error);
                }
            },
            patchUpdate: async (id, updateData) => {
                try {
                    const rs = await service.patchUpdate(id, updateData);
                    return this.handleApiResult(rs);
                }
                catch (error) {
                    this.handleErrorResult(error);
                }
            },
            getOne: async (id) => {
                try {
                    const rs = await service.getOne(id);
                    return this.handleApiResult(rs);
                }
                catch (error) {
                    this.handleErrorResult(error);
                }
            },
            getMany: async (filter) => {
                try {
                    const rs = await service.getMany(filter);
                    return this.handleApiResult(rs);
                }
                catch (error) {
                    this.handleErrorResult(error);
                }
            },
            delete: async (id) => {
                try {
                    await service.delete(id);
                }
                catch (error) {
                    this.handleErrorResult(error);
                }
            },
        };
    };
    setAccessToken(token, tokenMethod) {
        if (!token)
            return;
        this.api.client.setAccessToken(() => token, tokenMethod);
    }
    setErrorHandler(code, handler) {
        this.api.client.setErrorHandler(code, handler);
    }
}

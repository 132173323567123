import { BaseService } from "@core-ui/api-client";
export class CategoryService extends BaseService {
    constructor(api) {
        super(api);
    }
    create(createData) {
        return this.api.post("/categories", {}, createData);
    }
    update(id, updateData) {
        return this.api.put("/categories/{categoryId}", { categoryId: id }, updateData);
    }
    patchUpdate(categoryId, updateData) {
        return this.api.patch("/categories/{categoryId}", { categoryId }, updateData);
    }
    getOne(id) {
        return this.api.get("/categories/{categoryId}", { categoryId: id });
    }
    getMany(filter) {
        return this.api.get("/categories", filter);
    }
    delete(id) {
        return this.api.delete("/categories/{categoryId}", { categoryId: id });
    }
}
